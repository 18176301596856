<template>
   <div id="imgBox">
      <img src="../assets/img/ABUIABAEGAAg5oGL9AUox9PE2wUw8Ac4zQY.png"
           alt="">
   </div>

</template>

<script>
export default {

}
</script>

<style lang='scss'>
#imgBox {
   width: 1000px;
   height: 800px;
   margin-top: -30px;
   margin: auto;
}
img {
   width: 100%;
   height: 80%;
   margin: auto;
}
</style>